<template>
  <div>   
    <v-row dense>
      <v-col>
        <NgNewOrders
          @openModalOrderDetail="openModalOrderDetail"
          @getCustomDataItem="getCustomDataItem"
        >
        </NgNewOrders>
      </v-col>
      <v-col>
        <NgDomicilieOrders
          @openModalOrderDetail="openModalOrderDetail"
          @getCustomDataItem="getCustomDataItem"
        >
        </NgDomicilieOrders>
      </v-col>
      <v-col>
        <NgStorePickupOrders
          @openModalOrderDetail="openModalOrderDetail"
          @getCustomDataItem="getCustomDataItem"
        >
        </NgStorePickupOrders>
      </v-col>
    </v-row>
    <v-dialog v-model="modalOrderDetail" width="100%" style="background-color: white">
      <NgModalDetailOrders
        :item=currentItem
        @closeModalOrderDetail="closeModalOrderDetail"
      ></NgModalDetailOrders
    ></v-dialog>
  </div>
</template>
<script>

import MixinActions from '@/components/mixins/MixinListActions';
import MixinModalDynamic from '@/components/mixins/MixinModalDynamic';
import NgNewOrders from './NgNewOrders'
import NgDomicilieOrders from './NgDomicilieOrders'
import NgStorePickupOrders from './NgStorePickupOrders'
import NgModalDetailOrders from './NgModalDetailOrders';

export default {
  name: "NgModalOrdersReceive",
  mixins: [ MixinActions, MixinModalDynamic ],
  components: { NgNewOrders, NgDomicilieOrders, NgStorePickupOrders, NgModalDetailOrders },
  data: () => ({    
    app_version: null,
    items: null,
    currentItem: null,
    modalOrderDetail: false,
  }),
  methods: {
    openModalOrderDetail(item){
      this.modalOrderDetail = true;
      this.currentItem = item;
    },
    closeModalOrderDetail(){
      this.modalOrderDetail = false;
    },    
    getCustomDataItem(item){
      if(!item.data_customer){
        item.data_customer = {'full_name': 'No Registra'};
      } else {
        if(typeof item.data_customer.first_name != 'undefined' && item.data_customer.first_name){
          item.data_customer.full_name = item.data_customer.first_name;
          if(typeof item.data_customer.last_name != 'undefined' && item.data_customer.last_name){
            item.data_customer.full_name = item.data_customer.full_name + ' ' + item.data_customer.last_name;
          }
        }        
      }

      let fecha1 = new Date(item.date_order);
      let fecha2 = new Date()
      let resta = fecha2.getTime() - fecha1.getTime()
      item.time_elapsed = "Hace " + Math.round(resta / (1000*60*60*24)) + " horas.";
      //item.date_order = this.getDate(item.date_order);
      item.date_order = new Date(item.date_order);

      this.getDataPayments(item);
    },
		getDate(dateOrder) {
			return this.formatDate(new Date(dateOrder));
		},
		formatDate(v) {
			let month = '' + (v.getMonth() + 1);
			let day = '' + v.getDate();
			const year = v.getFullYear();
			if (month.length < 2) { month = '0' + month };
			if (day.length < 2) { day = '0' + day };
      let date = [year, month, day].join('-');

      let hour = '' + v.getHours();
      let minutes = '' + v.getMinutes();
      let second = '' + v.getSeconds();
      let time = [hour, minutes, second].join(':');

			return date + " " + time;
		},
    getDataPayments(item){
      let total = 0;
      let paymentMethods = '';
      let detailPayment = {};
      if(item.data_payments){
        for (const payment of item.data_payments) {
          total = (total + payment.value);
          paymentMethods += payment.payment_method.name + ",";
        }
      }
      item.total = total;
      item.payment_methods = paymentMethods.slice(0, -1);
    }
  },
};
</script>
