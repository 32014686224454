<template>
  <div>
    <AppMenu />
    <ng-modal-orders-receive></ng-modal-orders-receive>
  </div>
</template>
<script>
import AppMenu from "../menu/AppMenu.vue";
import NgModalOrdersReceive from "./OrderReceive/NgModalOrdersReceive";

export default {
  name: "OrdersReceive",
  components: { NgModalOrdersReceive, AppMenu },
  data: () => ({
    app_version: null,
  }),
  mounted() {
    this.load();
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    async load() {
      setTimeout(async () => {
        this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;
        await this.$db.load();
        const installed = await this.$db.itsInstalled();
        if (!installed) {
          this.openInstall = true;
        } else {
          this.installedEvent();
        }
      }, 50);
    },
    async installedEvent() {
      this.installed = true;
      this.openInstall = false;
    },
    launchMenuPos(evt) {
      return false;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
  },
};
</script>
