<template>
  <div>
    <v-card min-width="100%">    
      <v-app-bar>
        <v-toolbar-title>Para Recoger</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col v-for="(item, i) in items" :key="i" cols="12">
            <v-card>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.data_customer.full_name"
                  ></v-card-title>
                  <v-card-subtitle>
                    {{item.data_customer.phone}}
                    <br>
                    {{$filters.currency(item.total_to_pay)}} - {{item.time_elapsed}}
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      @click="$emit('openModalOrderDetail', item)"
                      class="ml-2"
                      outlined
                      rounded
                      small
                    >
                      Modificar
                    </v-btn>
                  </v-card-actions>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>

export default {
  name: "NgStorePickupOrders",
  data: () => ({    
    items: null,
  }),
  mounted(){
    this.getNewOrders();
  },
  methods: {
    getNewOrders(){
      let params = {
        'store_pickup': true        
      };
      this.$http.get("api/v1/order", params, true, false)
      .then((response) => {
        if (response.success) {
          if(response.items){
						this.items = response.items.map((item) => {
              item.payment_methods = '';
              this.$emit('getCustomDataItem', item);
              return item;
						});            
          }          
        }
      })
      .catch((e) => {
      }); 
    },
  },
};
</script>
