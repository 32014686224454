<template>
  <div>

    <v-card max-width="100%">
      <v-app-bar>
        <v-toolbar-title>Detalle de la Orden</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="$emit('closeModalOrderDetail')">mdi-window-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container>
        <v-row dense>

          <v-col cols="12" class="pa-0 ma-0">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Estado de la Orden") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.order_status }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="item.date_order"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Hora de Pedido") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.date_order }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="item.data_customer.full_name"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Cliente") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.data_customer.full_name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="item.data_customer.phone"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Teléfono") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.data_customer.phone }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="item.data_customer.address"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Dirección de Entrega") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.data_customer.address }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                
                <v-divider v-if="(item.total >= 0)"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Total Productos") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $filters.currency(item.total) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                
                <v-divider v-if="(item.price_domicile >= 0)"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Total Domicilio") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $filters.currency(item.price_domicile) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                
                <v-divider v-if="(item.price_tip >= 0)"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Total Propina") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $filters.currency(item.price_tip) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                
                <v-divider v-if="(item.total_to_pay >= 0)"></v-divider>                
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Total a Pagar") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $filters.currency(item.total_to_pay) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                
                <v-divider v-if="item.payment_methods"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Medio de Pago") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.payment_methods }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Detalle del Pago") }}</v-list-item-title>
                    <v-list-item-subtitle>

                      <v-list-item v-for="(pay, index) in item.data_payments" :key="index">
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("Método de Pago") }}: {{pay.payment_method.name}}</v-list-item-title>
                          <v-list-item-subtitle v-if="pay.reference"> {{ $t("Transacción No.") }} {{ pay.reference }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Domicilio") }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <ng-select-options class="font-weight-medium black--text text--black subtitle-2"
                        v-model="domiciliary"
                        :label="$t('Domiciliary')"
                        :items="domiciliaries"
                        :flat="false" :filled="false" dense :outlined="false" hide-details                    
                      ></ng-select-options>                      
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Tiempo Estimado") }}</v-list-item-title>
                    <v-list-item-subtitle>---</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <div class="d-flex justify-end pt-4 pb-2">
                  <v-btn class="mr-2" color="primary" @click="printOrder(item)">
                    {{$t("Imprimir Orden")}}
                  </v-btn>
                  <v-btn class="mr-2" color="primary" @click="dispachOrder(item)">
                    {{$t("Despachar Orden")}}
                  </v-btn>
                  <v-btn class="mr-2" color="primary" @click="cancelOrder(item)">
                    {{$t("Cancelar Orden")}}
                  </v-btn>
                </div>

                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("Detalle del Pedido") }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <!--v-list-item-title>{{ $t("Tiempo Estimado") }}</v-list-item-title-->
                      <v-list-item-subtitle>

                        <v-list-item v-for="(detail, index) in item.data_items" :key="index">
                          <v-list-item-content>
                            <v-list-item-title>{{detail.product.name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="detail.product.sub_items">
                            Mi subitems
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>                  
                </v-list-group>

                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("Estado del Pedido") }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <!--v-list-item-title>{{ $t("Tiempo Estimado") }}</v-list-item-title-->
                      <v-list-item-subtitle>
                        Estados y demás
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>                  
                </v-list-group>

              </v-list>
            </v-col>

        </v-row>
      </v-container>

    </v-card>

  </div>
</template>
<script>

import MixinActions from '@/components/mixins/MixinListActions';
import MixinModalDynamic from '@/components/mixins/MixinModalDynamic';

export default {
  name: "NgModalDetailOrders",
  mixins: [ MixinActions, MixinModalDynamic ],
  props: ["item"],
  data: () => ({
    domiciliaries: [],
    domiciliary: null,
  }),
  mounted(){
  },
  methods: {
    getDomicialiries(){
      this.domiciliaries = this.$db.getEmployeesByType('deliverer');
      // this.domiciliaries = domiciliaries;
    },
    printOrder(item){
      alert("Impriendo");
    },
    dispachOrder(item){
      alert("Despachando");
    },
    cancelOrder(item){
      alert("Cancelando");
    }
  },
};
</script>
